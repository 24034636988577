import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SideBar from '../components/SideBar';
import { useContext } from 'react';
import AffiliatePill from '../components/AffiliatePill';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  Fab,
  Box,
  Stack,
  Button,
  ButtonGroup,
  Chip,
  TextField,
} from '@mui/material';
import { AiOutlineMail } from 'react-icons/ai';
import { BsPlusLg } from 'react-icons/bs';
import { BiMinus } from 'react-icons/bi';
import PopupComponet from '../components/PopupComponent';
import CheckboxBlankIcon from '../images/checkbox_fill.png';
import CheckboxIcon from '../images/checkbox_green.png';
import { AnimationLoading } from '../components/Lottie';
import Businessman from '../images/businessman_white.png';
import * as API from "../utils/api";
import {ContextUser }from "../App"
function PageAffiliiate() {

  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const user = useContext(ContextUser);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  
  const [affiliateData, setAffiliateData] = useState({
    tier1: 'Tier 1',
    tier2: 'Tier 2',
    freeUser: 'Free User',
    paidUser: 'Paid User',
    freeAffiliate: 'Free Affiliate',
    paidAffiliate: 'Paid Affiliate',
  });

  useEffect(() => {
    async function run() {
      try {
        setLoading(true);
        const config = {email:user.email};
        const referrals = await API.GetAPI('/api/referral/network', true, config);
        console.log(referrals);
        setUsers(referrals ? referrals : []);
        setAllUsers(getOneBigArray( referrals ? referrals : []))
      } catch (e) {
        console.log(e.message);
      }finally{
        setLoading(false);
      }
    }

    function getOneBigArray(allNetworks){

      //declare arrays
      const list = []
 
      //get all the subusers and inner subusers
      allNetworks.forEach(user => recursiveGetSubusers (user, 1));
   
      function recursiveGetSubusers (user, tier){
        user.subusers.forEach(u => u.tier = tier);
        list.push(...user.subusers);
        user.subusers.forEach(u => recursiveGetSubusers(u, tier+1));
      }
  
      //sort data alphabetically
      return list.sort(function(a, b){
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;
        return 0;
      })
    }
  
    run();
  }, [user]);


  const handleChipBtn = (e) => {
    if (e.target.innerText === 'Tier 1')
      setAffiliateData({
        ...affiliateData,
        tier1: !affiliateData.tier1 ? 'Tier 1' : '',
      });
    if (e.target.innerText === 'Tier 2')
      setAffiliateData({
        ...affiliateData,
        tier2: !affiliateData.tier2 ? 'Tier 1' : '',
      });
    if (e.target.innerText === 'Free User')
      setAffiliateData({
        ...affiliateData,
        freeUser: !affiliateData.freeUser ? 'Free User' : '',
      });
    if (e.target.innerText === 'Paid User')
      setAffiliateData({
        ...affiliateData,
        paidUser: !affiliateData.paidUser ? 'Paid User' : '',
      });
    if (e.target.innerText === 'Free Affiliate')
      setAffiliateData({
        ...affiliateData,
        freeAffiliate: !affiliateData.freeAffiliate ? 'Free Affiliate' : '',
      });
    if (e.target.innerText === 'Paid Affiliate')
      setAffiliateData({
        ...affiliateData,
        paidAffiliate: !affiliateData.paidAffiliate ? 'Paid Affiliate' : '',
      });
  };

  const filterUsers = (user) => {
    return (
      user.status === affiliateData.freeUser ||
      user.status === affiliateData.paidUser ||
      user.status === affiliateData.freeAffiliate ||
      user.status === affiliateData.paidAffiliate ||
      user.status === affiliateData.tier1 ||
      user.status === affiliateData.tier2
    );
  };

  function searchFilter(user) {
    return (
      search.replace(/\s/gim, '') === '' ||
      user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  function showAffiliateNetwork(user, index) {
    return (
      <>
        <AffiliatePill key={index} user={user} tier={0} />
        {user.subusers
          .filter(filterUsers)
          .filter(searchFilter)
          .map((subuser, subIndex) => (
            <>
              <AffiliatePill key={subIndex} user={subuser} tier={1} />
              {subuser.subusers
                .filter(filterUsers)
                .filter(searchFilter)
                .map((tier2user, tierIndex) => (
                  <AffiliatePill key={tierIndex} user={tier2user} tier={2} />
                ))}
            </>
          ))}
      </>
    );
  }

  if(loading){
    return (
      <SideBar>
        <Container>
          <AnimationLoading title="Loading Users..."/>
        </Container>
      </SideBar>
    )
  }
 
  
  return (
    <SideBar>
      <Container>
        <Stack direction="row" spacing={2}>
        <Stack direction="row" spacing={3}>
            <Chip
              sx={{
                height: '28px',
                borderRadius: '50px',
                backgroundColor: '#FF557F',
              }}
              label={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '80px',
                      marginLeft: '-11px',
                    }}
                  >
                    <img
                      src={
                        affiliateData.tier1 ? CheckboxIcon : CheckboxBlankIcon
                      }
                      alt=""
                      width="15"
                      height="15"
                      style={{ borderRadius: '3px' }}
                    />
                    <span
                      style={{
                        fontSize: '14px',
                        marginLeft: '10px',
                        fontFamily: 'Reem Kufi, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '1px',
                      }}
                    >
                      Tier 1
                    </span>
                  </div>
                </>
              }
              color="primary"
              id="tier1"
              onClick={handleChipBtn}
            />

            <Chip
              sx={{
                height: '28px',
                borderRadius: '50px',
                backgroundColor: '#A027E7',
              }}
              label={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '80px',
                      marginLeft: '-11px',
                    }}
                  >
                    <img
                      src={
                        affiliateData.tier2 ? CheckboxIcon : CheckboxBlankIcon
                      }
                      alt=""
                      width="15"
                      height="15"
                      style={{ borderRadius: '3px' }}
                    />
                    <span
                      style={{
                        fontSize: '14px',
                        marginLeft: '10px',
                        fontFamily: 'Reem Kufi, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '1px',
                      }}
                    >
                      Tier 2
                    </span>
                  </div>
                </>
              }
              color="primary"
              id="tier2"
              onClick={handleChipBtn}
            />

            <Chip
              sx={{
                height: '28px',
                borderRadius: '50px',
              }}
              label={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '120px',
                      marginLeft: '-14px',
                    }}
                  >
                    <img
                      src={
                        affiliateData.freeUser
                          ? CheckboxIcon
                          : CheckboxBlankIcon
                      }
                      alt=""
                      width="15"
                      height="15"
                      style={{ borderRadius: '3px' }}
                    />
                    <span
                      style={{
                        fontSize: '14px',
                        marginLeft: '8px',
                        fontFamily: 'Reem Kufi, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '1px',
                      }}
                    >
                      Free User
                    </span>
                  </div>
                </>
              }
              color="primary"
              id="freeUser"
              onClick={handleChipBtn}
            />

            <Chip
              sx={{
                height: '28px',
                borderRadius: '50px',
              }}
              label={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '120px',
                      marginLeft: '0px',
                    }}
                  >
                    <img
                      src={
                        affiliateData.paidUser
                          ? CheckboxIcon
                          : CheckboxBlankIcon
                      }
                      alt=""
                      width="15"
                      height="15"
                      style={{ borderRadius: '3px' }}
                    />
                    <span
                      style={{
                        fontSize: '14px',
                        marginLeft: '10px',
                        fontFamily: 'Reem Kufi, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '1px',
                      }}
                    >
                      Paid User
                    </span>
                    <img
                      src={Businessman}
                      width="18"
                      height="20"
                      alt="businessman"
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </>
              }
              color="error"
              id="freeUser"
              onClick={handleChipBtn}
            />

            <Chip
              sx={{
                height: '28px',
                borderRadius: '50px',
              }}
              label={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '145px',
                      marginLeft: '-3px',
                    }}
                  >
                    <img
                      src={
                        affiliateData.freeAffiliate
                          ? CheckboxIcon
                          : CheckboxBlankIcon
                      }
                      alt=""
                      width="15"
                      height="15"
                      style={{ borderRadius: '3px' }}
                    />
                    <span
                      style={{
                        fontSize: '14px',
                        marginLeft: '7px',
                        fontFamily: 'Reem Kufi, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '1px',
                      }}
                    >
                      Free Affiliate
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                    >
                      <path
                        d="M285.213 212.865h53.154s2.903-63.743-62.294-72.879V115.9h1.038-25.33v23.465s-82.846 4.569-62.08 86.789c0 0 3.313 21.178 42.77 41.53 0 0 48.581 17.856 47.338 26.991 0 0 18.577 37.375-21.855 39.867 0 0-27.348-2.701-27.348-36.649H177.34s-3.736 68.52 70.705 75.374v22.423h25.221V374.2s55.136-1.871 63.541-55.126c0 0 13.397-50.769-58.548-78.797 0 0-39.562-12.461-39.249-34.883 0 0-1.246-21.18 13.39-24.607-.001-.001 31.674-11.833 32.813 32.078z"
                        fill="#f5f5f5"
                        className="fill-000000"
                      />
                    </svg>
                  </div>
                </>
              }
              color="success"
              onClick={handleChipBtn}
            />

            <Chip
              sx={{
                height: '28px',
                borderRadius: '50px',
              }}
              label={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '140px',
                      marginLeft: '-3px',
                    }}
                  >
                    <img
                      src={
                        affiliateData.paidAffiliate
                          ? CheckboxIcon
                          : CheckboxBlankIcon
                      }
                      alt=""
                      width="15"
                      height="15"
                      style={{ borderRadius: '3px' }}
                    />
                    <span
                      style={{
                        fontSize: '14px',
                        marginLeft: '8px',
                        marginRight: '1px',
                        fontFamily: 'Reem Kufi, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '1px',
                      }}
                    >
                      Paid Affiliate
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      height="20"
                    >
                      <path
                        d="M522 883q-74-162-74-371H192v96q0 78 94.5 162T522 883zm1078-275v-96h-256q0 209-74 371 141-29 235.5-113t94.5-162zm128-128v128q0 71-41.5 143t-112 130-173 97.5T1186 1023q-42 54-95 95-38 34-52.5 72.5T1024 1280q0 54 30.5 91t97.5 37q75 0 133.5 45.5T1344 1568v64q0 14-9 23t-23 9H480q-14 0-23-9t-9-23v-64q0-69 58.5-114.5T640 1408q67 0 97.5-37t30.5-91q0-51-14.5-89.5T701 1118q-53-41-95-95-113-5-215.5-44.5t-173-97.5-112-130T64 608V480q0-40 28-68t68-28h288v-96q0-66 47-113t113-47h576q66 0 113 47t47 113v96h288q40 0 68 28t28 68z"
                        fill="#fafafa"
                        className="fill-000000"
                      />
                    </svg>
                  </div>
                </>
              }
              color="warning"
              clickable
              onClick={handleChipBtn}
            />
          </Stack>
        </Stack>
      </Container>
      <Container className="centralise">
        <br />
        <TextField
          style={{ width: '70%' }}
          label="Search"
          name="search"
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Container>
      <Container>
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, ...rest }) => (
            <>
              <TransformComponent>
                <Stack
                  direction="column"
                  sx={{
                    width: '980px',
                    minHeight: '100vh',
                    marginLeft: '20px',
                  }}
                >
                  {users
                        .filter(filterUsers)
                        .filter(searchFilter)
                        .map(showAffiliateNetwork)}
                </Stack>
              </TransformComponent>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: '0',
                  right: '0',
                  marginBottom: '10px',
                  marginRight: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <h5 style={{ marginLeft: '30px' }}>
                  You have{' '}<strong>{users.length} user(s)</strong>
                </h5>
                <ButtonGroup orientation="horizontal">
                  <Button variant="contained" onClick={() => zoomIn()}>
                    <BsPlusLg />
                  </Button>
                  <Button variant="contained" onClick={() => zoomOut()}>
                    <BiMinus />
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleOpen}
                  >
                    <AiOutlineMail />
                  </Button>
                </ButtonGroup>
              </Box>
            </>
          )}
        </TransformWrapper>
        <PopupComponet open={open} handleClose={handleClose} />
      </Container>
    </SideBar>
  );
}

export default PageAffiliiate;
