import React, { useEffect, useState, useContext } from 'react'
import { Alert, Button, Col, Container, Form, FormControl, InputGroup, Modal, ProgressBar, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import swal from 'sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import LinkIcon from '@mui/icons-material/Link';
import { ContextUser } from '../App';
import { Select } from '@material-ui/core';
import { FormControlLabel, FormGroup, TextField } from '@mui/material';
import SideBar from '../components/SideBar';
import { FaSave } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from "axios";


const socialList = [
    "Website",
    "Youtube",
    "Facebook",
    "Twitter",
    "Instagram",
    "Tiktok",
    "Linkedin",
    "Discord",
    "Twitch",
    "Pinterest",
    "Snapchat",
    "Rumble",
    "Whatsapp"
]

function PageGroupEdit() {

    const { groupId } = useParams();
    const user = useContext(ContextUser);
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [publicGroup, setPublicGroup] = useState(true);
    const [password, setPassword] = useState("");
    const [description, setDescription] = useState("");
    const [resources, setResources] = useState([]);
    const [bottomBranding, setBottomBranding] = useState("");
    const history = useHistory();
    const [socialSettings, setSocialSettings] = useState([{ name: "Website", show: false, image: "https://img.icons8.com/fluency/48/000000/domain.png" },
    { name: "Youtube", show: false, image: "https://img.icons8.com/external-prettycons-flat-prettycons/47/000000/external-youtube-multimedia-prettycons-flat-prettycons.png" },
    { name: "Facebook", show: false, image: "https://img.icons8.com/color/48/000000/facebook.png" },
    { name: "Twitter", show: false, image: "https://img.icons8.com/color/48/000000/twitter.png" },
    { name: "Instagram", show: false, image: "https://img.icons8.com/fluency/48/000000/instagram-new.png" },
    { name: "Tiktok", show: false, image: "https://img.icons8.com/fluency/48/000000/tiktok.png" },
    { name: "Linkedin", show: false, image: "https://img.icons8.com/fluency/48/000000/linkedin.png" },
    { name: "Discord", show: false, image: "https://img.icons8.com/fluency/48/000000/discord.png" },
    { name: "Twitch", show: false, image: "https://img.icons8.com/fluency/48/000000/twitch.png" },
    { name: "Pinterest", show: false, image: "https://img.icons8.com/fluency/48/000000/pinterest.png" },
    { name: "Snapchat", show: false, image: "https://img.icons8.com/fluency/48/000000/snapchat.png" },
    { name: "Rumble", show: false, image: "https://firebasestorage.googleapis.com/v0/b/coaching360-beta-88999.appspot.com/o/rumble.png?alt=media&token=e300d534-dfb8-4091-97ae-cb42398709a4" },
    { name: "Whatsapp", show: false, image: "https://img.icons8.com/fluency/48/000000/whatsapp.png" }]
    );

    useEffect(() => {
        async function run() {
            try {
                const res = await API.GetAPI('/api/groups/group/' + groupId);
                if (res.group) {
                    setGroup(res.group);
                    setName(res.group.name);
                    setCode(res.group.code);
                    setDescription(res.group.description);
                    setBottomBranding(res.group.bottom_branding);
                }

            } catch (e) {
                console.log(e.message);
            }
        }

        run();
    }, [groupId]);

    useEffect(() => {
        async function run() {
            try {
                const resources = await API.GetAPI(`/api/resource`);
                setResources(resources);
            } catch (e) {
                console.log(e.message);
            }
        }
        run();
    }, []);

    const onUpdateImage = async (e) => {

        const file = e.target.files[0];
        const mbs = 2;
        const maxSize = mbs * 1000 * 1000;

        if (file.size > maxSize) {
            swal(`Image is too large. Image should be less than ${mbs}MB`);
            return;
        }

        if (!file.type.match(/image/gmi)) {
            swal(`Invalid image`);
            return;
        }

        try {
            setLoading(true);
            const token = await API.getAccessToken();
            const formdata = new FormData();
            formdata.append('image', file);
            formdata.append('name', file.filename);
            const domain = process.env.REACT_APP_BACKEND;
            const res = await axios({
                url: `${domain}/api/groups/group/${groupId}/upload`,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                data: formdata
            })

            if (res.data.result) {
                swal(res.data.message);
                setBottomBranding(res.data.url);
            }


        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }

       
    }


    const onEdit = async (e) => {
        e.preventDefault();

        const listOfResources = [];
        resources.forEach(res => {
            if (group.resources.find(rId => rId === res._id)) {
                listOfResources.push(res._id)
            }
        });

        const groupPayload = {
            name: name,
            code: code,
            description: description,
            resources: listOfResources,
            social: {},
            public: publicGroup,
            password: password,
        }

        try {
            setLoading(true);
            const res = await API.PatchAPI('/api/groups/' + group._id, groupPayload);
            swal(res.result ? 'Group updated' : 'Group Not Updated', res.message, res.result ? 'success' : 'info');
        } catch (e) {
            console.log(e.message);
            swal('Something went wrong. Check your internet.')
        } finally {
            setLoading(false);
        }
    }



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = resources;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setResources([...items]);
    }

    const isResourceThere = (res) => {
        const r = group.resources.find(rId => rId === res._id);
        return r !== null && r !== undefined;
    }

    const handleToggle = (res) => {

        const resourceIndex = group.resources.findIndex(rId => rId === res._id);
        if (resourceIndex !== -1) {
            delete group.resources[resourceIndex];
        } else {
            group.resources.push(res._id);
        }
        setGroup({ ...group });
    };

    const onBranding = () => document.getElementById('input').click();

    const onSocialSettings = async () => {
        try {
            setLoading(true);
            const res = await API.PatchAPI('/api/social/settings', socialSettings);
            swal('Inner Circle Deets', res.message, res.result ? "success" : "info");
        } catch (e) {
            console.log(e.message);
            swal('Inner Circle Deets', "Oh no! Check your internet ", "info");
        } finally {
            setLoading(false);
        }
    }


    if (!group) {
        return <AnimationLoading title="Loading Content..." />
    }

    return (
        <SideBar>
            <Container>
                <br /><br />
                <Button variant="light" onClick={() => history.push('/dashboard')} className="dropShadow hover round">
                    <strong>BACK TO DASH BOARD</strong>
                </Button>
                <br /><br />
                <Form onSubmit={onEdit} className="centralise">
                    <Alert variant="light" className="dropShadow">
                        <Row xs={1} sm={1} md={2}>
                            <Col>
                                <Alert.Heading><h2>Update {group.name}</h2></Alert.Heading>
                                <hr />
                                <TextField style={{ width: "100%" }} label={`Group Name (${name.length}/50)`} type="text" name="name" maxLength={50} value={name} onChange={e => setName(e.target.value)} />
                                <br /><br />
                                <TextField style={{ width: "100%" }} label={`Join Code (${code.length}/50)`} name="code" type="text" maxLength={50} value={code} onChange={e => setCode(e.target.value)} />
                                <br /><br />
                                <TextField style={{ width: "100%" }} label={`Description (${description.length}/200)`} multiline value={description} onChange={e => setDescription(e.target.value)} type="text" maxLength={200} />
                                <br />
                                {
                                    publicGroup ? null :
                                        <div>
                                            <Form.Label>Group Password ({password.length}/50)</Form.Label>
                                            <FormControl placeholder="Group Password" value={password} onChange={e => setPassword(e.target.value)} type="password" maxLength={50} />
                                        </div>
                                }
                                <br />
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={!publicGroup} onChange={e => setPublicGroup(!publicGroup)} />} label="Group Is Private" />

                                </FormGroup>

                                <br /><br />
                                <Button size="lg" style={{ position: "fixed", bottom: 30, right: 10 }} disabled={loading} variant="info" type="submit" className="round hover dropShadow">
                                    <FaSave size={30} />{" "}
                                    <strong>{loading ? "LOADING..." : `UPDATE ${group.name.toUpperCase()}`}</strong>
                                </Button>
                            </Col>


                            <Col>
                                <Alert.Heading>
                                    <h1>Social Links</h1>
                                    <h6>Update your social links <Link to="/social">here</Link></h6>
                                    <br />
                                    <Button variant="primary" className="dropShadow hover round" onClick={onSocialSettings}>
                                        <strong>Change Social Links</strong>
                                    </Button>
                                </Alert.Heading>
                                <Row xs={3}>
                                    {
                                        socialList.map(name =>
                                            <Col key={name}>
                                                <FormGroup>
                                                    <FormControlLabel control={<Switch checked={socialSettings.find(s => s.name === name).show} onChange={e => {
                                                        const value = e.target.checked;
                                                        const index = socialSettings.findIndex(s => s.name === name);
                                                        socialSettings[index].show = value;
                                                        setSocialSettings([...socialSettings]);
                                                    }} />} label={name} />

                                                </FormGroup>
                                            </Col>

                                        )
                                    }
                                </Row>
                            </Col>
                        </Row>

                    </Alert>
                </Form>
                <br />
                <Alert variant="light" className="dropShadow centralise">
                    <Alert.Heading>
                        <h1>Branding</h1>
                    </Alert.Heading>
                    <hr />
                    <input id="input" onChange={onUpdateImage} type="file" style={{ display: "none" }} accept="image/*" />
                    <img height={200} src={bottomBranding} alt="brand" />
                    <br /><br />
                    <Button variant="primary" className="dropShadow hover round" onClick={onBranding}>
                        <strong>Change Branding</strong>
                    </Button>
                </Alert>
                <br /><br />

                {/**https://www.youtube.com/watch?v=y1w6C9A5a2A */}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="social">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <List className="dropShadow" sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <ListSubheader>
                                            <h2>Your Links and Content</h2>
                                            <h5><i>Drag and Drop the item to organise resource listing</i></h5>
                                            <h6>You can edit your <strong>Links and Content</strong> <Link to="/links">here</Link></h6>
                                            <hr />
                                        </ListSubheader>
                                    }>

                                    {resources.map((res, index) => {
                                        return (
                                            <Draggable key={'d' + index} draggableId={'j' + index} index={index}>
                                                {(provided) => (
                                                    <Alert variant="light" style={{ alignContent: "left", alignItems: "left" }} className="dropShadow" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <ListItem>
                                                            <ListItemIcon> <LinkIcon /></ListItemIcon>
                                                            <ListItemText id="switch-list-label-wifi" primary={<Resources res={res} />} />
                                                            <Switch edge="end"
                                                                onChange={(value) => handleToggle(res)}
                                                                checked={isResourceThere(res)}
                                                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi', }}
                                                            />
                                                        </ListItem>
                                                    </Alert>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </List>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
               
            </Container>
        </SideBar>
    )
}

export default PageGroupEdit


function Resources({ res }) {

    const onCopy = () => {
        navigator.clipboard.writeText(res.url).then(() => {
            swal(res.name, `${res.name} link has been copied`, 'success');
        })
    };


    return <div>
        <h6><strong>{res.name}</strong></h6>
        <h6>{res.description}</h6>
        <Button variant="light" className="hover round" onClick={onCopy}>
            <LinkIcon />
            {" "}
            Copy
        </Button>
    </div>
}