import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// import main css
import './index.css';
import 'alertifyjs/build/css/alertify.css'
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// environment variables - https://www.npmjs.com/package/dotenv
require('dotenv').config();


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBAY27ism32Y-PUlhxdz5_nFFPi0AEMd-M",
  authDomain: "mysocial360boss.firebaseapp.com",
  projectId: "mysocial360boss",
  storageBucket: "mysocial360boss.appspot.com",
  messagingSenderId: "368349778641",
  appId: "1:368349778641:web:6e01604c47411a1d2e2bf0",
  measurementId: "G-SKRL6JJYH9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);