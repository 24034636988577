


import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import ConnectionError from "./components/ConnectionError";
import PageLogin from "./pages/PageLogin";
import PageHome from './pages/PageHome';
import PoweredByBOS from './components/PoweredByBOS';
import PageGroupPreview from './pages/PageGroupPreview';
import PageGroupEdit from './pages/PageGroupEdit';
import * as API from './utils/api';
import swal from 'sweetalert';
import PageOAuth from './pages/PageOAuth';
import PageCoaches from './pages/PageCoaches';
import PageSocial from './pages/PageSocial';
import PageGroup from './pages/PageGroup';
import PageStatistics from './pages/PageStatistics';
import PageAudience from './pages/PageAudience';
import PageAffiliate from './pages/PageAffiliate';
import PageNotifications from './pages/PageNotifications';
import PageSecurity from './pages/PageSecurity';
import PageIntegrations from './pages/PageIntegrations';
import PageResources from './pages/PageResources';


// use Context
export const ContextUser = React.createContext(null);
export const ContextSetUser = React.createContext(null);
export const ContextShareLink = React.createContext("");
export const ContextSetShareLink = React.createContext(null);
export const ContextReferral = React.createContext("");
export const ContextSetReferral = React.createContext(null);
export const ContextRegisterUrl = React.createContext("");
export const ContextSetRegisterUrl = React.createContext(null);
export const ContextCollapse = React.createContext(true);
export const ContextSetCollapse = React.createContext((value)=>null);


const App  = () => {

    const [user, setUser] = useState(null);
    const [shareLink, setShareLink] = useState("");
    const [referral, setReferral] = useState("");
    const [registerUrl, setRegisterUrl] = useState("");
    const [collapse, setCollapse] = useState(false);


    useEffect(() => {
        
        async function run(){
            
            const profile = await API.GetAPI('/api/user');
            
            if(profile?.result){
 
                const {user, my_groups, share_url, referral, register_url} = profile;
                setUser({...user, groups:my_groups});
                setShareLink(share_url);
                setRegisterUrl(register_url);
                setReferral(referral ? referral : "");

            }else if(profile.result === false){
                swal(profile.message);
            }
        }
        run();
    }, []);


    return (
        <>
          <Router>
                <ScrollToTop />
                <Switch>
                <ContextSetCollapse.Provider value={setCollapse}>
                <ContextCollapse.Provider value={collapse}>
                <ContextUser.Provider value={user}>
                <ContextSetUser.Provider value={setUser}>
                    <ContextShareLink.Provider value={shareLink}>
                    <ContextSetShareLink.Provider value={setShareLink}>
                        <ContextReferral.Provider value={referral}>
                        <ContextSetReferral.Provider value={setReferral}>
                        <ContextRegisterUrl.Provider value={registerUrl}>
                        <ContextSetRegisterUrl.Provider value={setRegisterUrl}>

                        {/**Auth Pages */}
                        <Route exact path="/" component={()=><PageHome />}  />
                        <Route exact path="/oauth" component={()=><PageOAuth />}  />
                        <Route exact path="/account"  component={()=><PageLogin />}  />
                        <Route exact path="/404" component={()=><ConnectionError message="Check your internet connection and re-open the extension" tryAgain={false} callback={null} />} />
                    
                        {/** Pages */}
                        <Route exact path="/dashboard" component={PageCoaches}  />
                        <Route exact path="/profile" component={PageCoaches}  />

                        <Route exact path="/social" component={PageSocial}  />
                        <Route exact path="/content" component={PageGroup}  />
                        <Route       path="/content/preview/:groupId" component={()=><PageGroupPreview />}  />
                        <Route       path="/content/edit/:groupId" component={()=><PageGroupEdit />}  />

                        <Route exact path="/links" component={PageResources}  />
                        <Route exact path="/audience" component={PageAudience}  />
                        <Route exact path="/affiliates" component={PageAffiliate}  />
                        <Route exact path="/notifications" component={PageNotifications}  />
                        <Route exact path="/statistics" component={PageSecurity}  />
                        <Route exact path="/security" component={PageStatistics}  />
                        <Route exact path="/integrations" component={PageIntegrations}  />
                        </ContextSetRegisterUrl.Provider>
                                </ContextRegisterUrl.Provider>
                            </ContextSetReferral.Provider>
                            </ContextReferral.Provider>
                        </ContextSetShareLink.Provider>
                        </ContextShareLink.Provider>
                    </ContextSetUser.Provider>
                    </ContextUser.Provider>
                    </ContextCollapse.Provider>
                    </ContextSetCollapse.Provider>
                </Switch>
                
            </Router>
            <PoweredByBOS/>
        </>
    );
}

export default App;