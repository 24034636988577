import React, { useContext } from 'react'
import { Container } from 'react-bootstrap';
import { ContextCollapse } from '../App';
import { AnimationComingSoon } from '../components/Lottie';
import SideBar from '../components/SideBar';

function PageStatistics() {

    const menuCollapse = useContext(ContextCollapse);
    return (
        <SideBar>
             <Container  className="centralise" style={{paddingTop:30}}>
                <AnimationComingSoon />
            </Container>
        </SideBar>
       
    )
}

export default PageStatistics
