import React, { useContext, useState } from 'react'
import { Container, Form, Button, FormControl, Alert, Modal, ProgressBar, Row, Col } from 'react-bootstrap'
import { ContextUser, ContextSetUser, ContextShareLink, ContextRegisterUrl, ContextReferral, ContextCollapse } from '../App';
import * as API from "../utils/api";
import swal from 'sweetalert';
import axios from "axios";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { TextField } from '@mui/material';
import { AiFillCopy, AiFillSave, AiOutlineLoading3Quarters } from 'react-icons/ai';
import SideBar from '../components/SideBar';
import { AnimationLoading } from '../components/Lottie';

function PageCoaches() {

    const [loading, setLoading] = useState(false);
    const user = useContext(ContextUser);
    const shareLink = useContext(ContextShareLink);
    const registerUrl = useContext(ContextRegisterUrl);
    const referral = useContext(ContextReferral);
    const setUser = useContext(ContextSetUser);
    const [image, setImage] = useState(user?.image);

    const [progress, setProgress] = useState(0);
    const [progressPopup, setProgressPopup] = useState(false);


    const onUpdate = async (e) => {
        e.preventDefault();
        try {
            const update = {
                name: e.target.name.value,
            };
            setLoading(true);
            const res = await API.PatchAPI('/api/user', update);
            user.name = update.name;
            swal(res.message);
        } catch (e) {
            console.log(e.message)
        } finally {
            setLoading(false);
        }
    };

    const onUpdateImage = async (e) => {

        const file = e.target.files[0];
        const mbs = 2;
        const maxSize = mbs * 1000 * 1000;

        if (file.size > maxSize) {
            swal(`Image is too large. Image should be less than ${mbs}MB`);
            return;
        }

        if (!file.type.match(/image/gmi)) {
            swal(`Invalid image`);
            return;
        }

        try {
            setLoading(true);
            const token = await API.getAccessToken();
            const formdata = new FormData();
            formdata.append('image', file);
            formdata.append('name', file.filename);
            const domain = process.env.REACT_APP_BACKEND;
            const res = await axios({
                url: `${domain}/api/user/image`,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                data: formdata
            })

            if (res.data.result) {
                swal(res.data.message);
                setImage(res.data.url);
            }


        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const onCopy = (context) => {
        navigator.clipboard.writeText(context).then(() => swal('Inner Circle Deets', `Copied your join link ${context}`, 'success'));
    }


    const onCode = async (e) => {
        e.preventDefault();
        try {

            const update = {
                code: e.target.code.value
            }

            setLoading(true);
            const res = await API.PatchAPI(`/api/user/code`, update);
            if (res.result) {
                user.code = res.id;
                user.code_update = true
                setUser({ ...user })
                swal(res.message)
            } else {
                swal(res.message)
            }
        } catch (e) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }
    }

    if (!user) {
        return (
            <>
                <SideBar>
                    <Container style={{ paddingTop: 30 }}>
                        <AnimationLoading title="Loading Profile" />
                    </Container>
                </SideBar>
            </>
        )
    }

    return (
        <SideBar>
            <Container style={{ paddingTop: 30 }}>
                <Alert variant="light" className="dropShadow">
                    <div className="centralise" >
                        <input id="input" onChange={onUpdateImage} type="file" style={{ display: "none" }} accept="image/*" />
                        <img src={image} width={150} height={150} className="dropShadow" alt="coach" style={{ borderRadius: "50%", padding: 14 }} />
                        <br /> <br />
                        <Button variant="primary" className="round" onClick={() => document.getElementById('input').click()}>
                            <img src="https://img.icons8.com/ios-glyphs/30/ffffff/user--v1.png" alt="profile" />{" "}
                            <strong>Upload New Profile Image</strong>
                        </Button>

                    </div>

                    <br />

                    <Form onSubmit={onUpdate}>
                        <Form.Label style={{ textAlign: "right" }}><h5>Display Name</h5></Form.Label>
                        <FormControl required name="name" maxLength={50} defaultValue={user?.name} type="name" size="lg" placeholder="Display Name" />
                        <br />
                        <Container className="centralise">
                            <Button disabled={loading} variant={loading ? "outline-info" : "outline-primary"} type="submit" className="round hover dropShadow" size="lg">
                                {loading ? <AiOutlineLoading3Quarters /> : <AiFillSave />
                                }
                                <strong>{loading ? "Loading..." : "Update Info"}</strong>
                            </Button>
                        </Container>
                        <br /><br />
                        <h6>Affiliate Share Link</h6>
                        <FormControl size="lg" style={{ float: "left", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: 660 }} label="Affiliate Share Link" defaultValue={registerUrl} disabled aria-label="Affiliate Share Link" />
                        <Button variant="info" size="lg" onClick={() => onCopy(registerUrl)}>
                            <AiFillCopy />
                        </Button>
                        <br />  <br />
                        <h6>Direct Download Link</h6>
                        <FormControl size="lg" style={{ float: "left", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: 660 }} label="Direct Download Link" defaultValue={shareLink} disabled aria-label="Direct Download Link" />
                        <Button variant="info" size="lg" onClick={() => onCopy(shareLink)}>
                            <AiFillCopy />
                        </Button>


                        <br /><br />
                        {referral !== "" ? <p>Referred by <strong>{referral}</strong></p> : null}

                    </Form>
                    {
                        user.code_updated ? <h6>Affiliate ID: <strong>{user.code}</strong></h6>
                            : <>
                                <Form onSubmit={onCode}>
                                    <Form.Label><h4>Your Referral Code: <strong>{user.code}</strong></h4></Form.Label>
                                    <br />
                                    <FormControl required name="code" defaultValue={user.code} type="code" size="lg" placeholder="Referral Code..." />

                                    <br /><br />
                                    <Button size="lg" disabled={loading} variant={loading ? "info" : "primary"} type="submit" className="round hover dropShadow">
                                        <img src="https://img.icons8.com/ios-filled/20/ffffff/digi-id.png" alt="join" />{" "}
                                        <strong>{loading ? "Loading..." : "Update Referral Code"}</strong>
                                    </Button>
                                </Form>
                            </>
                    }
                </Alert>
                <>


                </>
                <Modal show={progressPopup} onHide={() => setProgressPopup(false)}>
                    <Modal.Header>
                        <Modal.Title>UPLOADING IMAGE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Uploading {progress}%</h4>
                        <br />
                        <ProgressBar style={{ height: 15 }} variant="primary" min={0} max={100} now={progress} striped={true} animated={true} />
                    </Modal.Body>
                </Modal>
            </Container>
        </SideBar>
    )
}

export default PageCoaches
