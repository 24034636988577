import React, { useEffect, useState } from 'react'
import { FaUserCircle } from "react-icons/fa";
 
function UserImage({image, size, color}) {

    const [imageSource, setImageSource] = useState("");

    useEffect(()=>{
        async function run(){
            const res = await fetch(image);
            setImageSource(res.status < 400 ? image : "");
        }
        run();
    }, [image])

    if (imageSource === "") return <FaUserCircle color={color ? color : ""} size={size ? size : 20}/>
  
    return <img src={imageSource} width={size} alt="profile"  className="round" />
}



export default UserImage