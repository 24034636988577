import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
 
function ToolTip({children, text}) {
    return (
        <OverlayTrigger placement="bottom" delay={{ show: 450, hide: 800 }} 
            overlay={<Tooltip id={Math.random().toString()}>{text}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )
}

export default ToolTip
