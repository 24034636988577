import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import logo from "../images/logo.png";
import * as API from "../utils/api";
import { GoogleAuthProvider,FacebookAuthProvider ,signInWithPopup, getAuth, signOut   } from "firebase/auth";
import swal from 'sweetalert';
import { ContextSetUser } from '../App';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const PageLogin = () => {

    const setUser = useContext(ContextSetUser);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function run(){
            try{
                setLoading(true);
                const profile = await API.GetAPI('/api/user');
                if(profile?.result){
                    const u = profile.user;
                    u.share_url = profile.share_url;
                    u.register_url = profile.register_url;
                    setUser(u);
                    history.push('/dashboard');
                }
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
            
        }  
        run();
    }, [history, setUser]);


    const onFacebook = async () => {
        window.location.href = `${process.env.REACT_APP_BACKEND}/api/facebook/oauth/website`;
    }

    const onGoogle = async () => {
        window.location.href = `${process.env.REACT_APP_BACKEND}/api/google/oauth/website`;
    }


    return (
        <>
        
        <Container className="centralise" style={{paddingTop:250}}>
            <img src={logo} alt="logo" height={260}/>
            <h1>Join Inner Circle Deets</h1>
            <hr/>
         
            <Button disabled={loading} variant="primary" size="lg" className="round hover dropShadow" onClick={onFacebook}>
                <img src="https://img.icons8.com/fluency/35/000000/facebook-new.png" alt="Facebook"/>{" "}
                <strong>{loading ? "Loading..." : "Continue with Facebook"}</strong>
            </Button>
            <br/><br/>
            <Button disabled={loading} variant="light" size="lg" className="round hover dropShadow" onClick={onGoogle}>
                <img src="https://img.icons8.com/fluency/35/000000/google-logo.png" alt="Google"/>
                <strong>{loading ? "Loading...": "Continue with Google"}</strong>
            </Button>
           
        </Container>
        </>)
}

export default PageLogin
