import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Container, Form, FormControl, Modal } from 'react-bootstrap'
// Documentation - https://react-accessible-accordion.springload.co.nz/
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { AnimationDevelopers, AnimationLoading } from '../components/Lottie';
import rumble from "../images/rumble.png";
import * as API from "../utils/api";
import swal from 'sweetalert';
import {DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Switch from "react-switch";
import { ContextCollapse } from '../App';
import SideBar from '../components/SideBar';

function PageSocial() {

    const [social, setSocial] = useState(null)
    const [loading, setLoading] = useState(false);
    const [socialList, setSocialList] = useState([]);
    const [popup, setPopup] = useState(false);
    const menuCollapse = useContext(ContextCollapse);

    useEffect(() => {
        async function run(){
            const social = await API.GetAPI('/api/social');

            if(social){
                setSocial(social);
            }
        }
        run();
    }, [])

    const onDragEnd = (result) => {
      if (!result.destination) return;
  
      const items = socialList;
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setSocialList([...items]);
    }
  
    const onShowSocial = (social, value) => {
        const index= socialList.indexOf(social);
        socialList[index].show = value;
        setSocialList([...socialList]);
    }

    const onUpdateList = async () => {
        try{
            setLoading(true);
            const res = await API.PatchAPI('/api/social/settings', socialList);
            if(res.result){
                const soc = await API.GetAPI('/api/social');
                setSocialList(soc.settings?soc.settings:[]);
                swal(res.message);
                setPopup(false);
            }
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }

    const onSocial = async (e) => {
        e.preventDefault();
        const socialLinks = {
            website:e.target.website.value,
            youtube: {
                channel: e.target.youtubeChannel.value,
                video: e.target.youtubeVideo.value,
                live: e.target.youtubeLiveVideo.value, 
            },
            facebook: {
                profile: e.target.facebookProfile.value,
                page: e.target.facebookPage.value,
                messenger:e.target.facebookMessenger.value,
                live: e.target.facebookLiveVideo.value,
                group: e.target.facebookGroup.value,
            },
            twitter:{
                profile: e.target.twitterProfile.value, 
                messenger:e.target.twitterMessenger.value,
            },
            instagram:{
                profile: e.target.instagramProfile.value, 
                messenger:e.target.instagramMessenger.value,
            },
            tiktok:{
                profile: e.target.tiktokProfile.value, 
                messenger:e.target.tiktokMessenger.value,
            },
            linkedin:{
                profile: e.target.linkedinProfile.value, 
                messenger:e.target.linkedinMessenger.value,
                company:e.target.linkedinCompany.value,
            },
            discord:{
                profile: e.target.discordProfile.value, 
                messenger:e.target.discordMessenger.value,
                live:e.target.discordLiveVideo.value,
            },
            twitch:{
                profile: e.target.twitchProfile.value, 
                messenger:e.target.twitchMessenger.value,
                live:e.target.twitchLiveVideo.value,
            },
            pinterest:{
                profile: e.target.pinterestProfile.value, 
            },
            snapchat:{
                profile: e.target.snapchatProfile.value, 
            },
            rumble:{
                profile: e.target.rumbleProfile.value, 
            },
            whatsapp:e.target.whatsappNumber.value,
        }
 
        try{
            setLoading(true);
            const res = await API.PostAPI('/api/social', socialLinks);
            const social = await API.GetAPI('/api/social');
            setSocial(social);
            swal(res.message);
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false)
        }
    }
    
    const onClear = () => {
        
    }


    if(loading || social === null){
        return <SideBar><AnimationLoading width={400} title="Loading Social Links"/></SideBar>
    }

    
    return (
        <SideBar>
        <Container >
            <Alert vraiant="light" className="dropShadow">
                <Alert.Heading>
                    <h5>Social Links</h5>
                </Alert.Heading>
            </Alert>
            <br/>
            <Form onSubmit={onSocial}>
                <div className="centralise">
                    <Button variant="outline-warning"  className="hover round dropShadow" onClick={onClear}>
                        <img src="https://img.icons8.com/ios-filled/40/ffc107/clear-symbol.png" alt="clear"/>{" "}
                        <strong>CLEAR</strong>
                    </Button>
                    {" "}
                    <Button variant="warning" className="round hover dropShadow" type="submit">
                        <img src="https://img.icons8.com/glyph-neue/40/ffffff/approve-and-update.png" alt="update"/>
                        {" "}
                        <strong>UPDATE</strong>
                    </Button>
                    <br/> 
                    {/* <Button variant="light" size="lg" className="round hover dropShadow" onClick={()=>setPopup(true)}>
                        <img src="https://img.icons8.com/ios-glyphs/30/000000/settings--v1.png" alt="organise"/>{" "}
                        <strong>ORGANISE VIEWING</strong>
                    </Button> */}
                </div>
                
                <br/><br/>
                
                <Accordion allowZeroExpanded>
                    <AccordionItem key="website">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/domain.png" alt="website"/>
                                    {" "}
                                    <strong>WEBSITE</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Link</Form.Label>
                                <FormControl name="website" defaultValue={social?.website} type="url" size="lg" placeholder="Website" />
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="youtube">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/external-prettycons-flat-prettycons/47/000000/external-youtube-multimedia-prettycons-flat-prettycons.png" alt="youtube"/>
                                    {" "}
                                    <strong>YOUTUBE</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Channel Link</Form.Label>
                                <FormControl name="youtubeChannel" defaultValue={social?.youtube?.channel} type="url" size="lg" placeholder="Channel Link" />
                                <br/>
                                <Form.Label>Video Link</Form.Label>
                                <FormControl name="youtubeVideo" defaultValue={social?.youtube?.video} type="url" size="lg" placeholder="Video Link" />
                                <br/>
                                <Form.Label>Live Video Link</Form.Label>
                                <FormControl name="youtubeLiveVideo" defaultValue={social?.youtube?.live} type="url" size="lg" placeholder="Live Video Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="facebook">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/color/48/000000/facebook.png" alt="facebook"/>
                                    {" "}
                                    <strong>FACEBOOK</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Facebook Profile Link</Form.Label>
                                <FormControl name="facebookProfile" defaultValue={social?.facebook?.profile} type="url" size="lg" placeholder="Facebook Profile Link" />
                                <br/>
                                <Form.Label>Facebook Page Link</Form.Label>
                                <FormControl name="facebookPage" defaultValue={social?.facebook?.page} type="url" size="lg" placeholder="Facebook Page Link" />
                                <br/>
                                <Form.Label>Facebook Group Link</Form.Label>
                                <FormControl name="facebookGroup" defaultValue={social?.facebook?.group} type="url" size="lg" placeholder="Facebook Group Link" />
                                <br/>
                                <Form.Label>Facebook Messenger Link</Form.Label>
                                <FormControl name="facebookMessenger" defaultValue={social?.facebook?.messenger} type="url" size="lg" placeholder="Facebook Messenger Link" />
                                <br/>
                                <Form.Label>Facebook Live Video Link</Form.Label>
                                <FormControl name="facebookLiveVideo" defaultValue={social?.facebook?.live} type="url" size="lg" placeholder="Facebook Live Video Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="twitter">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/color/48/000000/twitter.png" alt="twitter"/>
                                    {" "}
                                    <strong>TWITTER</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Twitter Profile Link</Form.Label>
                                <FormControl name="twitterProfile" defaultValue={social?.twitter?.profile} type="url" size="lg" placeholder="Twitter Profile Link" />
                                <br/>
                                <Form.Label>Twitter Messenger Link</Form.Label>
                                <FormControl name="twitterMessenger" defaultValue={social?.twitter?.messenger} type="url" size="lg" placeholder="Twitter Messenger Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="instagram">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" alt="instagram"/>
                                    {" "}
                                    <strong>INSTAGRAM</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Instagram Profile Link</Form.Label>
                                <FormControl name="instagramProfile" defaultValue={social?.instagram?.profile}  type="url" size="lg" placeholder="Instagram Profile Link" />
                                <br/>
                                <Form.Label>Instagram Messenger Link</Form.Label>
                                <FormControl name="instagramMessenger" defaultValue={social?.instagram?.messenger}  type="url" size="lg" placeholder="Instagram Messenger Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="tiktok">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/tiktok.png" alt="tiktok"/>
                                    {" "}
                                    <strong>TIKTOK</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Tiktok Profile Link</Form.Label>
                                <FormControl name="tiktokProfile" defaultValue={social?.tiktok?.profile} type="url" size="lg" placeholder="Tiktok Profile Link" />
                                <br/>
                                <Form.Label>Tiktok Messenger Link</Form.Label>
                                <FormControl name="tiktokMessenger" defaultValue={social?.tiktok?.messenger} type="url" size="lg" placeholder="Tiktok Messenger Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="linkedin">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/linkedin.png" alt="linkedin"/>
                                    {" "}
                                    <strong>LINKEDIN</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Linkedin Profile Link</Form.Label>
                                <FormControl name="linkedinProfile" defaultValue={social?.linkedin?.profile} type="url" size="lg" placeholder="Linkedin Profile Link" />
                                <br/>
                                <Form.Label>Linkedin Company Link</Form.Label>
                                <FormControl name="linkedinCompany" defaultValue={social?.linkedin?.company} type="url" size="lg" placeholder="Linkedin Company Link" />
                                <br/>
                                <Form.Label>Linkedin Messenger Link</Form.Label>
                                <FormControl name="linkedinMessenger" defaultValue={social?.linkedin?.messenger} type="url" size="lg" placeholder="Linkedin Messenger Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="discord">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/discord.png" alt="discord"/>
                                    {" "}
                                    <strong>DISCORD</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Discord Profile Link</Form.Label>
                                <FormControl name="discordProfile" defaultValue={social?.discord?.profile} type="url" size="lg" placeholder="Discord Profile Link" />
                                <br/>
                                <Form.Label>Discord Messenger Link</Form.Label>
                                <FormControl name="discordMessenger" defaultValue={social?.discord?.messenger}  type="url" size="lg" placeholder="Discord Messenger Link" />
                                <br/>
                                <Form.Label>Discord Live Video Link</Form.Label>
                                <FormControl name="discordLiveVideo" defaultValue={social?.discord?.live}  type="url" size="lg" placeholder="Discord Live Video Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="twitch">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/twitch.png" alt="twitch"/>
                                    {" "}
                                    <strong>TWITCH</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Twitch Profile Link</Form.Label>
                                <FormControl name="twitchProfile" defaultValue={social?.twitch?.profile}  type="url" size="lg" placeholder="Twitch Profile Link" />
                                <br/>
                                <Form.Label>Twitch Messenger Link</Form.Label>
                                <FormControl name="twitchMessenger" defaultValue={social?.twitch?.messenger} type="url" size="lg" placeholder="Twitch Messenger Link" />
                                <br/>
                                <Form.Label>Twitch Live Video Link</Form.Label>
                                <FormControl name="twitchLiveVideo" defaultValue={social?.twitch?.live} type="url" size="lg" placeholder="Twitch Live Video Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="pinterest">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/pinterest.png" alt="pinterest"/>
                                    {" "}
                                    <strong>PINTEREST</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Pinterest Link</Form.Label>
                                <FormControl name="pinterestProfile" defaultValue={social?.pinterest?.profile} type="url" size="lg" placeholder="Pinterest Profile Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="snapchat">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/snapchat.png" alt="snapchat"/>
                                    {" "}
                                    <strong>SNAPCHAT</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Snapchat Link</Form.Label>
                                <FormControl name="snapchatProfile" defaultValue={social?.snapchat?.profile} type="url" size="lg" placeholder="Snapchat Profile Link" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="rumble">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src={rumble} width={48} alt="rumble"/>
                                    {" "}
                                    <strong>RUMBLE</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Rumble Channel/Profile</Form.Label>
                                <FormControl name="rumbleProfile" defaultValue={social?.rumble?.profile} type="url" size="lg" placeholder="Rumble Channel/Profile" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem key="whatsapp">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src="https://img.icons8.com/fluency/48/000000/whatsapp.png" alt="whatsapp"/>
                                    {" "}
                                    <strong>WHATSAPP</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <br/>
                                <Form.Label>Whatsapp Link</Form.Label>
                                <FormControl name="whatsappNumber" defaultValue={social?.whatsapp} type="tel" size="lg" placeholder="Whatsapp Number" />
                                <br/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

            </Form>

            <Modal show={popup} onHide={()=>setPopup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Organise List<br/></Modal.Title>
                    <hr/>
                    <h6><i>Drag and Drop the cards</i></h6>
                </Modal.Header>
                <Modal.Body>
                    {/**https://www.youtube.com/watch?v=y1w6C9A5a2A */}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="social">
                            {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {socialList.map((item, index) => {
                                return (
                                    <Draggable key={'d'+index} draggableId={'j'+index} index={index}>
                                    {(provided) => (
                                        <Alert variant="light" className="dropShadow" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <img src={item.image} alt='icon' style={{float:"left", width:40}} />{" "}
                                            <h4>{item.name}</h4>
                                            <Switch onColor="#0154AD" checked={item.show} onChange={(value)=>onShowSocial(item, value)} width={60} height={25} />
                                            <Form.Label><h4>Show</h4></Form.Label>
                                        </Alert>
                                    )}
                                    </Draggable>
                                );
                                })}
                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                    </DragDropContext>
            
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setPopup(false)}>
                        <strong>CANCEL</strong>
                    </Button>
                    {" "}
                    <Button variant="info" onClick={onUpdateList}>
                            <strong>UPDATE</strong>
                        </Button>
                </Modal.Footer> 
            </Modal>
        </Container>
        </SideBar>
    )
}

export default PageSocial