import React, { useContext, useEffect, useState } from 'react'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// ProSideBar
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';


// https://react-icons.github.io/react-icons/icons?name=ai
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { AiFillAudio, AiTwotoneApi } from "react-icons/ai";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { IconContext } from 'react-icons/lib';
import { FaThList } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { MdOutlineSocialDistance } from 'react-icons/md';
import { IoIosPeople, IoIosSettings } from 'react-icons/io';
import { BiBell, BiGroup, BiStats } from 'react-icons/bi';
import { ContextCollapse, ContextSetCollapse, ContextSetUser, ContextUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import * as API from "../utils/api";
import { AiFillEye, AiOutlineLink, AiFillEdit, AiFillDelete } from "react-icons/ai";
import { RiShareFill } from "react-icons/ri";


function SideBar({ children }) {

    const iconSize = "1.5em";
    const history = useHistory();
    const location = useLocation();

    const user = useContext(ContextUser);
    const setUser = useContext(ContextSetUser);

    const collapse = useContext(ContextCollapse);
    const setCollapse = useContext(ContextSetCollapse);
    const [resources, setResources] = useState([]);

    useEffect(() => {
        async function run() {
            try {
                const resources = await API.GetAPI(`/api/resource`);
                setResources(resources ? resources : []);
            } catch (e) {
                console.log(e.message);
            } finally {

            }
        }
        run()
    }, [])


    const onLogout = async () => {
        Cookies.set(process.env.REACT_APP_COOKIE_KEY, null);
        setUser(null);
        history.push('/');
        swal('Logout', 'Logout Successful. Please Come Again', 'success');
    }

    const selectedColor = (path) => location.pathname === `/${path}` ? "blue" : ""

    const openBOSDashboard = () => {
        window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank")
    }

    if (!user) {
        return <AnimationLoading width={700} title="Loading..." />
    }

    return (
        <>
            <ProSidebar style={{ position: "fixed" }} collapsed={collapse}>
                <SidebarHeader>
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="user"><h6>{user.email}</h6></Tooltip>}>
                        <Menu iconShape="circle">
                            <MenuItem onClick={() => setCollapse(!collapse)} icon={<img src={user?.image} alt="logo" width={70} height={70} style={{ borderRadius: "50%", padding: 14 }} />}>{user?.name}</MenuItem>
                        </Menu>
                    </OverlayTrigger>
                </SidebarHeader>

                <SidebarContent>
                    <Menu iconShape="square">

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Profile"><h6>Profile</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/profile')} icon={<IconContext.Provider value={{ color: selectedColor('profile') || selectedColor('dashboard'), size: iconSize }}><IoIosPeople /></IconContext.Provider>}>Profile</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Social"><h6>Social Links</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/social')} icon={<IconContext.Provider value={{ color: selectedColor('social'), size: iconSize }}><RiShareFill /></IconContext.Provider>}>Social Links</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Links"><h6>My Links</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/links')} suffix={<Badge pill variant="dark">{resources.length === 0 ? "Add New" : resources.length}</Badge>} icon={<IconContext.Provider value={{ color: selectedColor('links'), size: iconSize }} > <AiOutlineLink /></IconContext.Provider>}>My Links</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Deets"><h6>Deets</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/content')} icon={<IconContext.Provider value={{ color: selectedColor('content'), size: iconSize }}><FaThList /></IconContext.Provider>}>Deets</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Statistics"><h6>Statistics</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/statistics')} icon={<IconContext.Provider value={{ color: selectedColor('statistics'), size: iconSize }}><BiStats /></IconContext.Provider>}>Statistics</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Audience"><h6>Audience</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/audience')} icon={<IconContext.Provider value={{ color: selectedColor('audience'), size: iconSize }}><AiFillAudio /></IconContext.Provider>}>Audience</MenuItem>
                        </OverlayTrigger>

                        {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Affiliates"><h6>Affiliates</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/affiliates')} icon={<IconContext.Provider value={{ color: selectedColor('affiliates'), size: iconSize }}><HiUserGroup /></IconContext.Provider>}>Affiliates</MenuItem>
                        </OverlayTrigger> */}

                        {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Notifications"><h6>Notifications</h6></Tooltip>}>
                            <MenuItem onClick={() => history.push('/notifications')} icon={<IconContext.Provider value={{ color: selectedColor('notifications'), size: iconSize }}><BiBell /></IconContext.Provider>}>Notifications</MenuItem>
                        </OverlayTrigger> */}

                    </Menu>
                </SidebarContent>

                <SidebarFooter>
                    <Menu iconShape="round">

                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="collapse"><h6>BOS Applications Dashboard</h6></Tooltip>}>
                            <MenuItem onClick={openBOSDashboard} icon={<img src="https://app.mysocial360.com/logo/bos" alt="bos" width={20} />}>BOS Applications</MenuItem>
                        </OverlayTrigger>

                        <SubMenu title="Settings" icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><IoIosSettings /></IconContext.Provider>}>
                            <MenuItem onClick={() => history.push('/integrations')}>Integrations</MenuItem>
                            <MenuItem onClick={() => history.push('/security')}>Security</MenuItem>
                        </SubMenu>

                        <SubMenu title="About Us" icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><AiTwotoneApi /></IconContext.Provider>}>
                            <MenuItem><a target="_blank" rel="noreferrer" href="https://app.mysocial360.com/terms">Terms Of Service</a></MenuItem>
                            <MenuItem><a target="_blank" rel="noreferrer" href="https://app.mysocial360.com/privacypolicy">Privacy Policy</a></MenuItem>
                        </SubMenu>


                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Logout"><h6>Logout</h6></Tooltip>}>
                            <MenuItem onClick={onLogout} icon={<IconContext.Provider value={{ color: "#BB0000", size: iconSize }}><RiLogoutCircleRFill /></IconContext.Provider>}>Logout</MenuItem>
                        </OverlayTrigger>


                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="collapse"><h6>{collapse ? "Expand" : "Collapse"}</h6></Tooltip>}>
                            <MenuItem onClick={() => setCollapse(!collapse)} icon={<IconContext.Provider value={{ color: "#FFFFFF", size: iconSize }}>{collapse ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}</IconContext.Provider>}>{collapse ? "Expand" : "Collapse"}</MenuItem>
                        </OverlayTrigger>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
            <main style={{ zIndex: 0, position: "absolute", top: 40, left: collapse ? 110 : 400, height: "80%", width: collapse ? "90vw" : "60vw", overflowY: "visible" }}>
                {children}
            </main>
        </>
    )
}

export default SideBar