import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table, FormControl, Button, Alert, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { ContextCollapse } from '../App';
import Detail from '../components/Detail';
import SideBar from '../components/SideBar';
import * as API from "../utils/api";
import { AiOutlinePlus } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { TextField } from '@mui/material';
import ToolTip from '../components/ToolTip';
import { AiFillEye, AiOutlineLink, AiFillEdit, AiFillDelete } from "react-icons/ai";




function PageGroup() {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("")
    const [description, setDescription] = useState("")
    const [groups, setGroups] = useState([]);
    const [publicGroup, setPublicGroup] = useState(true);
    const [password, setPassword] = useState("");

    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function run() {
            try {
                setLoading(true);
                const groups = await API.GetAPI('/api/groups');
                setGroups(groups ? groups : []);
            } catch (e) {
                console.log(e.message);
                setGroups([]);
            } finally {
                setLoading(false)
            }
        }

        run();
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const onAddGroup = async (e) => {
        e.preventDefault();

        const group = {
            name: name,
            code: code,
            description: description,
            resources: [],
            social: {},
            public: publicGroup,
            password: password,
        }

        try {
            setLoading(true);
            const res = await API.PostAPI('/api/groups', group);
            swal(res.result ? 'Group Created' : 'Group Not Created', res.message, res.result ? 'success' : 'info');
            const groups = await API.GetAPI('/api/groups');
            setGroups(groups);
        } catch (e) {
            console.log(e.message);
            swal('Something went wrong. Check your internet.')
        } finally {
            setLoading(false);
        }
    }


    const onDel = async (page) => {
        const options = {
            title: "Delete Group",
            text: `Do you want to delete ${page.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }
        const result = await swal(options);
        if (result) {
            try {
                setLoading(true);
                const res = await API.DeleteAPI(`/api/groups/${page._id}`);
                swal('Group Delete', res.message, 'info');
                const groups = await API.GetAPI('/api/groups');
                setGroups(groups ? groups : []);
            } catch (e) {
                console.log(e.message);
            } finally {
                setLoading(false);
            }
        }
    }

    const onCopy = (page) => {
        navigator.clipboard.writeText(`https://app.mysocial360.com/innercircledeets/deets/${page.code}`).then(
            () => swal(page.name, `Copied Code for ${page.code}`, 'success')
        )
    }

    const filter = (group) => {
        return (search.replace(/\s/gmi, '') === "" ||
            group.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            group.description.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            group.code.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }

    return (
        <SideBar>
            <Container className="centralise">
                <h2>INNER CIRCLE DEETS</h2>
                <h4>Content and Links</h4>
                <br />
                <TextField style={{ width: "70%" }} label={<h6><FaSearch size={10} /> Search</h6>} value={search} onChange={e => setSearch(e.target.value)} />
                <br /><br />
                <Row xs={1} sm={2} md={2} lg={3}>
                    {
                        groups.filter(filter).map((page, index) => (
                            <Col key={index}>

                                <Alert style={{ height: 220 }} variant={page.public ? "light" : "dark"} className='round dropShadow centralise'>
                                    <Alert.Heading><h5><strong>{page.name}</strong></h5></Alert.Heading>
                                    <Alert.Heading><h6>{page.description}</h6></Alert.Heading>
                                    <Alert.Heading><h6><i>Code: <strong>{page.code}</strong></i></h6></Alert.Heading>

                                    {/** Buttons */}
                                    <hr />
                                    <ToolTip text="Preview">
                                        <Button disabled={loading} variant="primary" as={Link} to={`/content/preview/${page._id}`} className="hover round dropShadow">
                                            <AiFillEye size={20} />
                                        </Button>
                                    </ToolTip>

                                    <ToolTip text="Copy Link">
                                        <Button disabled={loading} variant="primary" onClick={() => onCopy(page)} className="hover round dropShadow">
                                            <AiOutlineLink size={20} />
                                        </Button>
                                    </ToolTip>

                                    <ToolTip text="Edit Deet">
                                        <Button disabled={loading} variant="primary" as={Link} to={`/content/edit/${page._id}`} className="hover round dropShadow">
                                            <AiFillEdit size={20} />
                                        </Button>
                                    </ToolTip>

                                    <ToolTip text="Delete">
                                        <Button disabled={loading} variant="dark" onClick={() => onDel(page)} className="hover round dropShadow">
                                            <AiFillDelete size={20} />
                                        </Button>
                                    </ToolTip>


                                </Alert>
                            </Col>

                        ))
                    }
                </Row>
                <br />

                {/* <Table variant="light" className="dropShadow">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>NAME</th>
                            <th>CODE</th>
                            <th>DESCRIPTION</th>
                            <th>PUBLIC</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            groups.filter(filter).map((page, index) =>

                                <tr key={page._id}>
                                    <td>{index + 1}</td>
                                    <td>{page.name}</td>
                                    <td>{page.code}</td>
                                    <td><Detail max={100} text={page.description} /></td>
                                    <td>{page.public ? "YES" : "NO"}</td>
                                    <td>

                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table> */}
                <Button variant="primary" style={{ position: "fixed", bottom: 30, right: 50 }} className="hover round dropShadow" size='lg' onClick={handleShow}>
                    <AiOutlinePlus size={30} />
                    <strong>ADD</strong>
                </Button>

                <br /><br /><br /><br />
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Content For Your Inner Circle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onAddGroup}>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading><h2>Create Grouped Content</h2></Alert.Heading>
                            <hr />
                            <Form.Label>Group Name ({name.length}/50)</Form.Label>
                            <FormControl placeholder="Group Name" type="text" name="name" maxLength={50} value={name} onChange={e => setName(e.target.value)} />
                            <br />
                            <Form.Label>Join Code ({code.length}/50)</Form.Label>
                            <FormControl placeholder="Join Code" name="code" type="text" maxLength={50} value={code} onChange={e => setCode(e.target.value)} />
                            <br />
                            <Form.Label>Description ({description.length}/200)</Form.Label>
                            <FormControl placeholder="Description" value={description} onChange={e => setDescription(e.target.value)} type="text" as="textarea" style={{ height: 80 }} maxLength={200} />
                            <br />
                            {
                                publicGroup ? null :
                                    <div>
                                        <Form.Label>Group Password ({password.length}/50)</Form.Label>
                                        <FormControl placeholder="Group Password" value={password} onChange={e => setPassword(e.target.value)} type="password" maxLength={50} />
                                    </div>
                            }
                            <br />
                            <Form.Check checked={!publicGroup} onChange={e => setPublicGroup(!publicGroup)} label="Group is private" />
                            <br />
                            <Button style={{ display: "none" }} id="submit" type="submit" className="round hover dropShadow">
                                <strong>{loading ? "LOADING..." : "ADD NEW GROUP"}</strong>
                            </Button>
                        </Alert>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>CLOSE</Button>
                    <Button variant="primary" disabled={loading} onClick={() => document.getElementById('submit').click()}> <strong>{loading ? "LOADING..." : "ADD NEW GROUP"}</strong></Button>
                </Modal.Footer>
            </Modal>
        </SideBar>
    )
}

export default PageGroup
