import React, {useEffect, useState} from 'react';
import { Badge } from 'react-bootstrap';
import { AiTwotoneMail } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Stack, Container, Chip } from '@mui/material';
import UserImage from '../components/UserImage';

function AffiliatePill({
  image,
  name,
  tier,
  total_affiliates,
  direct_affiliates,
}) {

  const [margin, setMargin] = useState();

  useEffect(()=>{
    setMargin(tier * 45);
  },[])
 

  // A027E7 purple;
  // FF557F pink;
  // FFC107 YELLOW;

  return (
    <div
      className="dropShadow round hover"
      style={{
        width: 250,
        height: 45,
        marginLeft:margin,
        backgroundColor: '#f9f9f9',
        margin: '5px 0',
        padding: '2px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '50px',
      }}
    >
      <div
        style={{
          backgroundColor: '#f3f3f3',
          width: '42px',
          height: '42px',
          borderRadius: '50%',
        }}
      >
        <UserImage image={image} size={42} color="lightgray" />
      </div>
      <div
        style={{
          flexGrow: '1',
          display: 'flex',
          flexDirection: 'column',
          aligntItems: 'flex-start',
          marginLeft: '10px',
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <div>
            <span>
              <FcGoogle size={8} />
            </span>
            <span style={{ marginLeft: '10px' }}>
              <FaFacebook size={8} />{' '}
            </span>
            <span style={{ marginLeft: '10px' }}>
              <AiTwotoneMail size={8} />
            </span>
          </div>
          <Stack
            direction="row"
            spacing={1}
            style={{
              lineHeight: 0,
              fontSize: '10px',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '10px',
                backgroundColor: '#FF557F',
                color: '#fff',
                borderRadius: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '8px',
                fontWeight: 'bold',
              }}
            >
              T1-999
            </div>
            <div
              style={{
                width: '30px',
                height: '10px',
                backgroundColor: '#A027E7',
                color: '#fff',
                borderRadius: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '8px',
                fontWeight: 'bold',
              }}
            >
              T2-999
            </div>
          </Stack>
        </Stack>
        <h6
          style={{
            lineHeight: 0,
            fontSize: '11px',
            fontWeight: 'bold',
            marginTop: '2px',
          }}
        >
          {name}
        </h6>
        <Stack
          direction="row"
          spacing={1}
          style={{
            marginBottom: '4px',
            lineHeight: 0,
            fontSize: '10px',
          }}
        >
          <Badge pill variant="primary">
            392
          </Badge>
          <Badge pill variant="danger">
            556
          </Badge>
          <Badge pill variant="warning">
            555
          </Badge>
          <Badge pill variant="success">
            555
          </Badge>
        </Stack>
      </div>
      <div
        style={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          width="35"
          height="35"
        >
          <path
            d="M285.213 212.865h53.154s2.903-63.743-62.294-72.879V115.9h1.038-25.33v23.465s-82.846 4.569-62.08 86.789c0 0 3.313 21.178 42.77 41.53 0 0 48.581 17.856 47.338 26.991 0 0 18.577 37.375-21.855 39.867 0 0-27.348-2.701-27.348-36.649H177.34s-3.736 68.52 70.705 75.374v22.423h25.221V374.2s55.136-1.871 63.541-55.126c0 0 13.397-50.769-58.548-78.797 0 0-39.562-12.461-39.249-34.883 0 0-1.246-21.18 13.39-24.607-.001-.001 31.674-11.833 32.813 32.078z"
            fill="#18ab1f"
            className="fill-000000"
          />
        </svg>
      </div>
      <div
        style={{
          width: '10%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <div
          style={{
            width: '30px',
            height: '15px',
            backgroundColor: 'crimson',
            color: '#fff',
            borderRadius: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10px',
            fontWeight: 'bold',
          }}
        >
          999
        </div>
      </div>
    </div>
  );
}

export default AffiliatePill;
