import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, FormControl, Row } from 'react-bootstrap';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import swal from 'sweetalert';
import { ContextCollapse, ContextUser } from '../App';
import SideBar from '../components/SideBar';
import { AiFillMessage } from 'react-icons/ai';

function PageNotifications() {

    const user = useContext(ContextUser);
    const menuCollapse = useContext(ContextCollapse);
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [message, setMessage] = useState("");
    const [icon, setIcon] = useState(user?.image || "https://firebasestorage.googleapis.com/v0/b/mysocial360boss.appspot.com/o/icon192.png?alt=media&token=400716f9-1106-4fdd-b0c3-46e3acd20ff7");
    const [useButtons, setUseButtons] = useState(false);
    const [button1, setButton1] = useState("");
    const [button2, setButton2] = useState("");
    const [buttonUrl1, setButtonUrl1] = useState("");
    const [buttonUrl2, setButtonUrl2] = useState("");
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([]);


    useEffect(() => {
        async function run() {
            try {
                const members = await API.GetAPI('/api/member/install');
                setMembers(members);
            } catch (e) {
                console.log(e.message)
                setMembers([]);
            }
        }

        run();
    }, []);


    const openBrowser = (url) => {

        const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        if (url.match(expression)) {
            window.open(url, "_blank")
        }

    }

    const updateUrl = (link) => {

        if (link.match(/^www/gmi)) {
            link = "https://" + link;
        }
        return link;
    }

    const onInstallMessage = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const message = {
                title: "Inner Circle Deets",
                subtitle: "From " + user.name,
                message: e.target.message.value,
                icon: user.image,
                buttons: [],
                image: ""
            }

            const res = await API.PostAPI('/api/message/install', message);
            if (res.result) {
                swal(res.message);
            }

        } catch (e) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const buttons = [];
        if (useButtons) {
            const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            if (button1 !== "" && buttonUrl1 !== "" && buttonUrl1.match(expression)) {
                buttons.push({
                    title: button1,
                    url: buttonUrl1
                })
            }

            if (button2 !== "" && buttonUrl2 !== "" && buttonUrl2.match(expression)) {
                buttons.push({
                    title: button2,
                    url: buttonUrl2
                })
            }
        }

        const ids = members.map(member => member.notificationId);

        const firebaseMessage = {
            notificationIds: ids,
            title: title,
            subtitle: subtitle,
            message: message,
            type: "basic",
            icon: icon,
            //img:"", 
            //buttons: buttons,
        }

        try {
            setLoading(true)
            const response = await API.PostAPI('/api/notifications/send/gcm', firebaseMessage);
            console.log(response);
            setLoading(false);
            swal("Notification Sent");
        } catch (e) {
            console.log(e.message);
            setLoading(false)
            alert('Failed');
        } finally {
            setLoading(false)
        }

    }

    return (
        <SideBar>
            <Container className="centralise">

                <Alert variant="light" className="dropShadow">
                    <Alert.Heading><h1>On Install App</h1></Alert.Heading>
                    <hr />
                    <Form onSubmit={onInstallMessage}>
                        <Form.Label style={{ float: "left" }}><h6>Welcome message</h6></Form.Label>
                        <FormControl disabled={loading} required type="text" name="message" as="textarea" style={{ height: 80 }} placeholder="Message for people who install chrome extension via your share link" size="lg" />
                        <br /><br />
                        <Button disabled={loading} variant="outline-dark" className="round hover dropShadow" type="submit">
                            <AiFillMessage />{" "}
                            <strong>SET WELCOME MESSAGE</strong>
                        </Button>
                    </Form>
                </Alert>
                <Row xs={2}>
                    {loading ? <AnimationLoading title="Loading..." /> :
                        <Col>

                            <Alert variant="light" className="dropShadow">
                                <img width={150} height={150} src={`https://img.icons8.com/color/150/000000/send.png`} alt="notifications" />
                                <h3><strong>Send Quick Notification</strong></h3>
                                <h5><i>Sending Notifications to Everyone</i></h5>
                                <br />
                                <Form onSubmit={onSubmit}>
                                    <img src={icon} height={80} width={80} alt="Icon" />
                                    <br />
                                    <Form.Label style={{ float: "left" }}><h6>Icon Link</h6></Form.Label>
                                    <FormControl required type="url" placeholder="Icon Link" size="lg" value={icon} onChange={(e) => setIcon(updateUrl(e.target.value))} />

                                    <br />
                                    <Form.Label style={{ float: "left" }}><h6>Title</h6></Form.Label>
                                    <FormControl required type="text" placeholder="Title" maxLength={60} size="lg" value={title} onChange={(e) => setTitle(e.target.value)} />
                                    <br />
                                    <Form.Label style={{ float: "left" }}><h6>Subtitle</h6></Form.Label>
                                    <FormControl type="text" placeholder="Subtitle" maxLength={60} size="lg" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
                                    <br />
                                    <Form.Label style={{ float: "left" }}><h6>Message</h6></Form.Label>
                                    <FormControl required type="text" as="textarea" placeholder="Message..." maxLength={120} size="lg" value={message} onChange={(e) => setMessage(e.target.value)} />
                                    <br />
                                    <Form.Label style={{ float: "left" }}><h6>Notification Link</h6></Form.Label>
                                    <FormControl type="url" placeholder="Notification Link" size="lg" value={url} onChange={(e) => setUrl(updateUrl(e.target.value))} />
                                    <br />
                                    <h6>Use Buttons</h6>
                                    <Form.Check type="checkbox" checked={useButtons} onChange={e => setUseButtons(e.target.checked)} label="Use Buttons" />
                                    <br />
                                    {
                                        useButtons ?
                                            <>
                                                <Row xs={2}>
                                                    <Col>
                                                        <FormControl maxLength={20} type="text" placeholder="Button 1 Text" value={button1} onChange={(e) => setButton1(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <FormControl type="url" placeholder="Button 1 Link" value={buttonUrl1} onChange={(e) => setButtonUrl1(updateUrl(e.target.value))} />
                                                    </Col>
                                                </Row>
                                                <Row xs={2}>
                                                    <Col>
                                                        <FormControl maxLength={20} type="text" placeholder="Button 2 Text" value={button2} onChange={(e) => setButton2(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <FormControl type="url" placeholder="Button 2 Link" value={buttonUrl2} onChange={(e) => setButtonUrl2(updateUrl(e.target.value))} />
                                                    </Col>
                                                </Row>
                                            </>

                                            : null
                                    }
                                    <br />
                                    <Button variant="primary" size="lg" type="submit">
                                        <img width={40} height={40} src="https://img.icons8.com/ios-filled/30/ffffff/send.png" alt="primary" />
                                        {" "}
                                        <strong>Send</strong>
                                    </Button>
                                </Form>
                            </Alert>
                        </Col>

                    }


                    <Col>
                        <Alert variant="light" className="dropShadow">
                            <img height={150} width={150} src="https://img.icons8.com/cotton/150/000000/img.png" alt="preview" />
                            <h3><strong>Preview</strong></h3>
                            <br />
                            <Alert variant="light">
                                <Card className="dropShadow">
                                    <Card.Header>
                                        <Card.Title><h5>Chrome Extension</h5></Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row xs={icon === "" ? 1 : 2}>
                                            {
                                                icon === "" ? null :

                                                    <Col xs={4}>
                                                        <img src={icon} height={80} width={80} alt="Icon" />
                                                    </Col>
                                            }
                                            <Col xs={icon === "" ? 1 : 6}>
                                                <div style={{ float: "left" }}>
                                                    <h5 style={{ textAlign: "left", maxWidth: icon === "" ? 400 : 250 }}><strong>{title}</strong></h5>
                                                    <h6 style={{ textAlign: "left", maxWidth: icon === "" ? 400 : 250 }}>{subtitle}</h6>
                                                    <h6 style={{ textAlign: "left", maxWidth: icon === "" ? 400 : 250 }}><strong>{message}</strong></h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row xs={useButtons && (button1 === "" || button2 === "") ? 1 : 2}>
                                            <Col>
                                                {!useButtons || button1 === "" ? null : <Button variant="light" onClick={() => openBrowser(buttonUrl1)}>{button1}</Button>}
                                            </Col>
                                            <Col>
                                                {!useButtons || button2 === "" ? null : <Button variant="light" onClick={() => openBrowser(buttonUrl2)}>{button2}</Button>}
                                            </Col>
                                        </Row>
                                    </Card.Body>

                                </Card>
                            </Alert>
                        </Alert>
                    </Col>


                </Row>

            </Container>
        </SideBar>
    )
}

export default PageNotifications
