import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { errorToast } from '../utils/toaster';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import CheckboxBlankIcon from '../images/checkbox_fill.png';
import CheckboxIcon from '../images/checkbox_green.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const PopupComponet = ({ open, handleClose }) => {
  
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([].map((app) => app.name));
  const [right, setRight] = useState([]);
  const [affiliateData, setAffiliateData] = useState({
    title: '',
    titleError: '',
    message: '',
    messageError: '',
    freeUser: false,
    paidUser: false,
    freeAffiliate: false,
    paidAffiliate: false,
  });
  
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleChange = (e) => {
    setAffiliateData({
      ...affiliateData,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const handleChipBtn = (e) => {
    console.log(typeof e.target.innerText);
    if (e.target.innerText === 'Free User')
      setAffiliateData({ ...affiliateData, freeUser: !affiliateData.freeUser });
    if (e.target.innerText === 'Paid User')
      setAffiliateData({ ...affiliateData, paidUser: !affiliateData.paidUser });
    if (e.target.innerText === 'Free Affiliate')
      setAffiliateData({
        ...affiliateData,
        freeAffiliate: !affiliateData.freeAffiliate,
      });
    if (e.target.innerText === 'Paid Affiliate')
      setAffiliateData({
        ...affiliateData,
        paidAffiliate: !affiliateData.paidAffiliate,
      });
  };

  const handleSend = async () => {
    const apps = [...right];
    console.log(affiliateData);
    const { title, message, freeUser, paidUser, freeAffiliate, paidAffiliate } =
      affiliateData;

    console.log('second', affiliateData);
    if (apps.length < 1) return errorToast('select at least one application');
    if (title === '')
      return setAffiliateData({
        ...affiliateData,
        titleError: 'Title is required',
      });
    setAffiliateData({ ...affiliateData, titleError: '' });
    if (message === '' || message === undefined)
      return errorToast('Message is required');
    if (!freeUser && !paidUser && !freeAffiliate && !paidAffiliate)
      return errorToast('Select at least one affiliate plan');

    try {
      const res = await axios({
        method: 'post',
        url: 'http://localhost:7000/admin/admin/send/notification',
        data: {
          apps: apps,
          title: title,
          message: message,
          free_users: freeUser,
          paid_users: paidUser,
          free_affiliates: freeAffiliate,
          paid_affiliates: paidAffiliate,
        },
      });
      handleClose();
      swal({ title: 'Message was sent to affiliates', icon: 'success' });
      setAffiliateData({
        title: '',
        titleError: '',
        message: '',
        messageError: '',
        freeUser: false,
        paidUser: false,
        freeAffiliate: false,
        paidAffiliate: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const customList = (items) => (
    <Paper
      sx={{
        width: 230,
        height: 230,
        overflow: 'auto',
      }}
    >
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              sx={{
                background: 'whiteSmoke',
                borderRadius: '30px',
                border: '1px solid #d5d5d5',
                marginBottom: '10px',
                height: '30px',
              }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <div className="">
      <Modal open={open} onClose={handleClose}>
        <form action="">
          <ToastContainer />
          <Box
            sx={{
              ...style,
              width: 700,
              height: 550,
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderBottom: '1px solid #d5d5d5',
              }}
            >
              <Box
                sx={{
                  background: 'white',
                  width: '65%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Box sx={{ textAlign: 'center' }}>
                    <h6>Apps</h6>
                    <Grid>
                      <Grid item>{customList(left)}</Grid>
                    </Grid>
                  </Box>
                  <Stack spacing={2}>
                    <TextField
                      sx={{ width: '20ch', marginTop: '25px' }}
                      label="Search"
                    />
                    <Grid>
                      <Divider>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleAllRight}
                              disabled={left.length === 0}
                              aria-label="move all right"
                            >
                              ≫
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleCheckedRight}
                              disabled={leftChecked.length === 0}
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleCheckedLeft}
                              disabled={rightChecked.length === 0}
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleAllLeft}
                              disabled={right.length === 0}
                              aria-label="move all left"
                            >
                              ≪
                            </Button>
                          </Grid>
                        </Grid>
                      </Divider>
                    </Grid>
                  </Stack>
                </Stack>
                <Stack spacing={2} sx={{ marginTop: '20px' }}>
                  <TextField
                    required
                    label="Title"
                    name="title"
                    onChange={handleChange}
                    error={affiliateData.titleError !== '' ? true : false}
                    helperText={
                      affiliateData.titleError !== ''
                        ? affiliateData.titleError
                        : ''
                    }
                  />
                  <TextareaAutosize
                    minRows={3}
                    placeholder="Message"
                    name="message"
                    onChange={handleChange}
                  />
                </Stack>
              </Box>
              <Divider sx={{ width: '1px', background: '#d5d5d5' }} />
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: 'white',
                  width: '35%',
                  display: 'flex',
                }}
              >
                <h6>Selected Apps</h6>
                <Grid>
                  <Grid item>{customList(right)}</Grid>
                </Grid>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  sx={{ marginLeft: '10px', marginTop: '5px' }}
                >
                  <h6>Affiliate Plan</h6>
                  <Stack direction="row" spacing={2}>
                    <Chip
                      label={
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '50px',
                              marginLeft: '-10px',
                            }}
                          >
                            <img
                              src={
                                affiliateData.freeUser
                                  ? CheckboxIcon
                                  : CheckboxBlankIcon
                              }
                              alt=""
                              srcset=""
                              width="15"
                              height="15"
                              style={{ borderRadius: '3px' }}
                            />
                            <span
                              style={{ fontSize: '14px', marginLeft: '10px' }}
                            >
                              T1
                            </span>
                          </div>
                        </>
                      }
                      color="primary"
                      onClick={handleChipBtn}
                    />
                    <Chip
                      label={
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '50px',
                              marginLeft: '-10px',
                            }}
                          >
                            <img
                              src={
                                affiliateData.freeUser
                                  ? CheckboxIcon
                                  : CheckboxBlankIcon
                              }
                              alt=""
                              srcset=""
                              width="15"
                              height="15"
                              style={{ borderRadius: '3px' }}
                            />
                            <span
                              style={{ fontSize: '14px', marginLeft: '10px' }}
                            >
                              T2
                            </span>
                          </div>
                        </>
                      }
                      color="secondary"
                      onClick={handleChipBtn}
                    />
                  </Stack>
                  <Chip
                    label={
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '140px',
                            marginLeft: '-14px',
                          }}
                        >
                          <img
                            src={
                              affiliateData.freeUser
                                ? CheckboxIcon
                                : CheckboxBlankIcon
                            }
                            alt=""
                            srcset=""
                            width="20"
                            height="20"
                            style={{ borderRadius: '3px' }}
                          />
                          <span
                            style={{ fontSize: '14px', marginLeft: '10px' }}
                          >
                            Free User
                          </span>
                        </div>
                      </>
                    }
                    color="primary"
                    id="freeUser"
                    onClick={handleChipBtn}
                  />
                  <Chip
                    label={
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '140px',
                            marginLeft: '-14px',
                          }}
                        >
                          <img
                            src={
                              affiliateData.paidUser
                                ? CheckboxIcon
                                : CheckboxBlankIcon
                            }
                            alt=""
                            srcset=""
                            width="20"
                            height="20"
                            style={{ borderRadius: '3px' }}
                          />
                          <span
                            style={{ fontSize: '14px', marginLeft: '10px' }}
                          >
                            Paid User
                          </span>
                        </div>
                      </>
                    }
                    color="error"
                    onClick={handleChipBtn}
                  />
                  <Chip
                    label={
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '120px',
                          }}
                        >
                          <img
                            src={
                              affiliateData.freeAffiliate
                                ? CheckboxIcon
                                : CheckboxBlankIcon
                            }
                            alt=""
                            srcset=""
                            width="20"
                            height="20"
                            style={{ borderRadius: '3px' }}
                          />
                          <span
                            style={{ fontSize: '14px', marginLeft: '10px' }}
                          >
                            Free Affiliate
                          </span>
                        </div>
                      </>
                    }
                    color="success"
                    id="freeUser"
                    onClick={handleChipBtn}
                  />
                  <Chip
                    label={
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '120px',
                          }}
                        >
                          <img
                            src={
                              affiliateData.paidAffiliate
                                ? CheckboxIcon
                                : CheckboxBlankIcon
                            }
                            alt=""
                            srcset=""
                            width="20"
                            height="20"
                            style={{ borderRadius: '3px' }}
                          />
                          <span
                            style={{ fontSize: '14px', marginLeft: '10px' }}
                          >
                            Paid Affiliate
                          </span>
                        </div>
                      </>
                    }
                    color="warning"
                    id="freeUser"
                    onClick={handleChipBtn}
                  />
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ display: 'flex', marginTop: '10px' }}>
              <Button variant="contained" onClick={handleSend}>
                Send
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default PopupComponet;
