import React, { useContext, useEffect, useState } from 'react';
import { Container, Alert, Row, Col, Button, Modal, FormControl, Form, Table } from 'react-bootstrap';
import * as API from '../utils/api';
import { AnimationLoading } from '../components/Lottie';
import swal from 'sweetalert';
import { ContextCollapse, ContextUser } from '../App';
import SideBar from '../components/SideBar';
import { useCallback } from 'react';
import { Box, TextField, useTheme, FormGroup, FormControlLabel } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import MaterialFormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import countryList from 'country-list';
import { DataGrid } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



function PageAudience() {

    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popupMember, setPopupMember] = useState(null);
    const user = useContext(ContextUser);
    const [icon, setIcon] = useState(user?.image || "");
    const [facebookCount, setFacebookCount] = useState(0);
    const [googleCount, setGoogleCount] = useState(0);
    const [search, setSearch] = useState("");
    const [countries, setCountries] = useState([]);
    const [rows, setRows] = useState([]);
    const theme = useTheme();
    const [selectionModel, setSelectionModel] = useState([]);
    const [bulkMessage, setBulkMessage] = useState(false);
    const [mobile, setMobile] = useState(true);
    const [extension, setExtension] = useState(true);

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'image',
            headerName: 'Img',
            align: 'center',
            width: 60,
            renderCell: (params) => <img width={40} className="dropShadow" style={{ borderRadius: "50%" }} alt="profile" src={params.value} />,
        },
        { field: 'name', headerName: 'Name', align: 'center', width: 250 },
        { field: 'email', headerName: 'Email', align: 'center', width: 250 },
        // { field: 'country', headerName: 'Country', align: 'center', width: 150 },
        { field: 'date', headerName: 'Date', align: 'center', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            align: 'center',
            width: 250,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Button variant="primary" className="round hover dropShadow" onClick={() => setPopupMember(params.value)}>
                        Send Notification
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        async function run() {
            try {

                const getDate = (member) => {
                    const date = new Date(member.createdAt);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
                    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                    return `${day} ${months[month]} ${year}`;
                }

                const members = await API.GetAPI('/api/referral');
                setMembers(members);
                const countF = members.filter(m => m.facebook_id !== "").length;
                const countG = members.filter(m => m.google_id !== "").length;
                setFacebookCount(countF);
                setGoogleCount(countG);
                setRows(members.map(member => {
                    return {
                        ...member,
                        id: member._id,
                        actions: member,
                        date: getDate(member),
                        mobile: member.notification_mobile_id !== ""
                    }

                }))
            } catch (e) {
                console.log(e.message)
                setMembers([]);
            }
        }

        run();
    }, []);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        const list = typeof value === 'string' ? value.split(',') : value;
        setCountries(list);
    };

    const onNotification = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            console.log(user, popupMember);
            const message = {
                notificationIds: [popupMember.notification_id, popupMember.notification_mobile_id],
                title: e.target.title.value,
                subtitle: e.target.subtitle.value,
                message: e.target.message.value,
                url: e.target.url.value,
                icon: icon,
                from: `From ${user.name}`,
                fromNotificationId: user.notification_id
            }
            const send = await API.PostAPI('/api/notifications/send/gcm', message);
            console.log(send);
            setPopupMember(null);
        } catch (e) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }
    }

    const onOpenBulkDialogue = () => {
        if (mobile || extension) setBulkMessage(true)
        else swal('Inner Circle Deets', 'Please select either mobile or extension', 'info');
    }

    const onSendBulkNotification = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);

            const message = {
                notificationIds: [],
                title: e.target.title.value,
                subtitle: e.target.subtitle.value,
                message: e.target.message.value,
                url: e.target.url.value,
                icon: icon,
                from: `From ${user.name}`,
                fromNotificationId: user.notification_id
            }


            if (selectionModel.length) {
                const list = rows.filter(r => selectionModel.indexOf(r.id) !== -1);
                list.forEach(member => {
                    if (member.notification_id) message.notificationIds.push(member.notification_id)
                    if (member.notification_mobile_id) message.notificationIds.push(member.notification_mobile_id)
                })
            } else {

                //send everyone
                if (countries.indexOf("All Contries") !== -1 || countries.length === 0) {
                    rows.forEach(member => {
                        if (member.notification_id && extension) message.notificationIds.push(member.notification_id)
                        if (member.notification_mobile_id && mobile) message.notificationIds.push(member.notification_mobile_id)
                    });
                } else { //filter by country
                    const list = rows.filter(member => countries.find(country => country.toLowerCase() === member.country.toLowerCase()));
                    list.forEach(member => {
                        if (member.notification_id && extension) message.notificationIds.push(member.notification_id)
                        if (member.notification_mobile_id && mobile) message.notificationIds.push(member.notification_mobile_id)
                    })
                }
            }

            if (message.notificationIds.length) {
                const send = await API.PostAPI('/api/notifications/send/gcm', message);
                console.log(send);
            } else return swal('No one was found who met the criteria');

            setBulkMessage(false);
            
        } catch (e) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }

    }

    const onDownload = () => {
        const emails = members.map(member => member.email + "\n");
        const blob = new Blob(emails, { type: 'type/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "emails.csv")
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const onCopy = () => {
        let emails = "";
        members.forEach(member => emails += member.email + "\n");
        navigator.clipboard.writeText(emails).then(() => swal(`You've copied ${members.length} email(s)`));
    }



    const filter = useCallback((person) => {
        return (search.replace(/\s/gmi, '') === "" ||
            person.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            person.email.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }, [search]);

    return (
        <SideBar>
            <Container >
                <h1 className="centralise">AUDIENCE LIST</h1>
                <hr />
                <TextField style={{ width: "100%" }} label={<h6><FaSearch size={20} /> Search</h6>} value={search} onChange={e => setSearch(e.target.value)} />
                <br /><br />

                {/* <MaterialFormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="countries">Select Countries</InputLabel>
                    <Select
                        labelId="countries"
                        id="countries"
                        multiple
                        value={countries}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-countries" label="Countries" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => <Chip key={value} label={value.toUpperCase()} />)}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >

                        {["All Contries", ...Object.keys(countryList.getNameList())].map((country, index) =>
                            <MenuItem key={index} value={country} style={getStyles(country, countries, theme)}>
                                {country}
                            </MenuItem>)}
                    </Select>
                </MaterialFormControl> */}
                <Button disabled={loading} variant="primary" className="round hover dropShadow" size="lg" onClick={onOpenBulkDialogue}>
                    <strong>SEND NOTIFICATIONS</strong>
                </Button>
                {" "}
                <FormGroup>
                    <FormControlLabel control={<Switch checked={mobile} onChange={e => setMobile(!mobile)} />} label="Send Mobile" />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={extension} onChange={e => setExtension(!extension)} />} label="Send Extension" />
                </FormGroup>
                <br /><br />
                {/* <Row xs={2}>
                    <Col>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading><h4>Audience {members.length === 1 ? <strong>1 Person</strong> : <strong>{members.length} People</strong>}</h4></Alert.Heading>
                            <hr />
                            <br />
                            <Row xs={2}>
                                <Col>
                                    <h5>
                                        <strong>{facebookCount} From Facebook</strong>
                                        <img src={"https://img.icons8.com/fluency/24/000000/facebook.png"} alt="facebook" />

                                    </h5>
                                </Col>
                                <Col>
                                    <h5>
                                        <strong>{googleCount} From Google</strong>
                                        <img src={"https://img.icons8.com/color/24/000000/gmail--v1.png"} alt="google" />

                                    </h5>
                                </Col>
                            </Row>

                        </Alert>
                    </Col>
                    <Col>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading><h4>Emails</h4></Alert.Heading>
                            <hr />
                            <br />
                            <Row xs={1} sm={2}>
                                <Col>
                                    <Button variant="light" className="round dropShadow" onClick={onDownload}>
                                        <img src="https://img.icons8.com/color/30/000000/csv.png" alt="csv" />
                                        <strong>Download Email List</strong>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button variant="light" className="round dropShadow" onClick={onCopy} >
                                        <img src="https://img.icons8.com/color/30/000000/copy.png" alt="csv" />
                                        <strong>Copy Email List</strong>
                                    </Button>
                                </Col>
                            </Row>
                        </Alert>
                    </Col>
                </Row>
                <br /><br />

                <h3>
                    <img src="https://img.icons8.com/color/40/000000/chrome--v1.png" alt="chrome" />
                    Referrals
                </h3> */}
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={rows.filter(filter)}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        checkboxSelection
                        onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                    />
                </div>
                {/* <Table variant="light">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>COUNTRY</th>
                            <th>DATE</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            members.filter(filter).map((member, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><img src={member.image} alt="member" width={40} className="round" />{" "}{member.name}</td>
                                    <td>
                                        {member.facebook_id !== "" ? <img className="round" src={"https://img.icons8.com/fluency/24/000000/facebook.png"} alt="facebook" /> : <img src={"https://img.icons8.com/fluency/24/000000/chrome.png"} alt="chrome" />}
                                        {" "}
                                        {member.email}
                                    </td>
                                    <td>Malawi</td>
                                    <td>
                                        {getDate(member)}
                                    </td>
                                    <td>
                                        <Button variant="primary" className="round hover dropShadow" onClick={() => setPopupMember(member)}>
                                            Send Notification
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table> */}
                <Modal show={popupMember} onHide={() => setPopupMember(null)}>
                    <Modal.Header>
                        <Modal.Title>SEND NOTIFICATION MESSAGE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={onNotification}>
                            {
                                loading ? <AnimationLoading title="Loading" width={300} /> :
                                    <>
                                        <img src={icon} height={80} width={80} alt="Icon" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Icon Link</h6></Form.Label>
                                        <FormControl required type="url" placeholder="Icon Link" size="lg" value={icon} onChange={(e) => setIcon(e.target.value)} name="icon" />

                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Title</h6></Form.Label>
                                        <FormControl required type="text" placeholder="Title" maxLength={60} size="lg" name="title" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Subtitle</h6></Form.Label>
                                        <FormControl type="text" placeholder="Subtitle" maxLength={60} size="lg" name="subtitle" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Message</h6></Form.Label>
                                        <FormControl required type="text" as="textarea" placeholder="Message..." maxLength={120} size="lg" name="message" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Notification Link</h6></Form.Label>
                                        <FormControl type="link" placeholder="Notification Link" size="lg" name="url" />
                                        <br />
                                        <button id="button-notification" style={{ display: "none" }} type="submit"></button>
                                    </>
                            }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="lg" onClick={() => setPopupMember(null)}>
                            <strong>CANCEL</strong>
                        </Button>
                        <Button disabled={loading} variant="primary" size="lg" onClick={() => document.getElementById('button-notification').click()}>
                            <strong>SEND</strong>
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={bulkMessage} onHide={() => setBulkMessage(false)}>
                    <Modal.Header>
                        <Modal.Title>SEND BULK NOTIFICATION MESSAGE</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={onSendBulkNotification}>
                            {
                                loading ? <AnimationLoading title="Loading" width={300} /> :
                                    <>
                                        <img src={icon} height={80} width={80} alt="Icon" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Icon Link</h6></Form.Label>
                                        <FormControl required type="url" placeholder="Icon Link" size="lg" value={icon} onChange={(e) => setIcon(e.target.value)} name="icon" />

                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Title</h6></Form.Label>
                                        <FormControl required type="text" placeholder="Title" maxLength={60} size="lg" name="title" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Subtitle</h6></Form.Label>
                                        <FormControl type="text" placeholder="Subtitle" maxLength={60} size="lg" name="subtitle" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Message</h6></Form.Label>
                                        <FormControl required type="text" as="textarea" placeholder="Message..." maxLength={120} size="lg" name="message" />
                                        <br />
                                        <Form.Label style={{ float: "left" }}><h6>Notification Link</h6></Form.Label>
                                        <FormControl type="link" placeholder="Notification Link" size="lg" name="url" />
                                        <br />
                                        <button id="button-notification" style={{ display: "none" }} type="submit"></button>
                                    </>
                            }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="lg" onClick={() => setBulkMessage(false)}>
                            <strong>CANCEL</strong>
                        </Button>
                        <Button disabled={loading} variant="primary" size="lg" onClick={() => document.getElementById('button-notification').click()}>
                            <strong>SEND</strong>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </SideBar>
    );
}

export default PageAudience;
